<template>
    <section class='container py-5'>
        <div class='mx-auto'>
            <h3> {{ $t('search-category-h3') }} </h3>
            <div id="categories" class='col-sm-8 col-lg-6 hideContent mx-auto'>
              <button v-bind:key="index" v-for="(cat, index) in categories.all" :id="'category-'+cat" class="no-underline btn btn-sm btn-outline-warning link my-1 mx-1" v-on:click="categorySearch(cat)">
                {{ cat }}
              </button>
            </div>
            <p id='show-more-categories' style='text-decoration: underline' @click="showMore()" tabindex="0"> {{ $t('search-see-more') }} </p>
            <!-- <p class='col-6 mx-auto' id='show-more-categories' @click="showMore()" tabindex="0"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-caret-down-square" viewBox="0 0 16 16"><path d="M3.626 6.832A.5.5 0 0 1 4 6h8a.5.5 0 0 1 .374.832l-4 4.5a.5.5 0 0 1-.748 0l-4-4.5z"/><path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm15 0a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2z"/></svg></p> -->
            <p> {{ $t('search-category-or') }} </p>
            <button id="category-random" class='btn btn-outline-warning mt-2 mx-2 active' @click="fetchAll"> {{ $t('search-category-random') }} </button>
            <button id="category-random-spinner" class='btn btn-outline-warning mt-2 mx-2 active d-none'>
              <div class="spinner-border" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </button>

            <!-- Cards -->
            <div class='col-lg-8 mx-auto' id='loading-cards-spinner'>
              <div class="spinner-border my-5" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </div>
            <CardsList v-bind:cards="cards"/>
        </div>
        <div>
            <button id='load-more' class="no-underline btn btn-lg btn-outline-success link" v-on:click="loadMore()" tabindex="0"> {{ $t('search-cards-load-more') }} </button>
            <button id='load-more-spinner' class="no-underline btn btn-lg btn-outline-success link d-none">
              <div class="spinner-border" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </button>
        </div>
    </section>
</template>

<script>
import CardsList from '../components/card/CardsList';

import axios from 'axios';
import { mapState } from 'vuex'

export default {
  name: 'Search',
  metaInfo: {
    title: 'Search',
    titleTemplate: '%s | Chachi',
  },
  components: {
    CardsList
  },
  data() {
    return {
      title: "All",
      categories: {'all': ['Loading', 'something', 'amazing', 'for', 'you!']}
    }
  },
  computed: mapState({
    isLoggedIn: state => state.isLoggedIn,
    currentUser: state => state.currentUser,
    cards: state => state.cards,
    currentCardIndex: state => state.currentCardIndex,
    lang: state => state.lang,
    category: state => state.category,
    categoryValue: state => state.categoryValue
  }),
  beforeMount() {
    // set language
    if (this.lang === null) { this.$store.commit('setLang', 'en') }
    
    // code for pulling search categories from API
    axios.get('https://api.chachi.app/v2/bank/categories')
      .then( response => {
        this.categories = response.data
        if (this.lang === 'en') { this.categories.all = response.data.en }
        if (this.lang === 'hi') { this.categories.all = response.data.hi }
      })
      .catch( err => console.log(err) )
    
    // first load fetch
    if (this.currentCardIndex === -1) {
      this.fetchMore(0)
    }
    // this.infiniteMore();
  },
  updated() {
    // don't show spinner if cards exist
    // this happens when user returns from another page
    if (this.cards.length > 0) {
      document.getElementById('loading-cards-spinner').classList.add('d-none')
    }
    this.footerText()
  },
  methods: {
    footerText() {
      var enFooterStrings = [ " people are talking", " people are reading",
                        " people are viewing", " users are discussing"]  
      var hiFooterStrings = [ " लोग इस पर बात कर रहे हैं", " लोग इसे पढ़ रहे हैं",
                        " लोग इसे देख रहे हैं", " लोग इस पर चर्चा कर रहे हैं"]

      if (this.lang === 'en') { var footerStrings = enFooterStrings }
      else if (this.lang === 'hi') { footerStrings = hiFooterStrings }
      // console.log(this.cards.length)
      var startingCard = this.cards.length - 4
      for (var i=startingCard; i < this.cards.length; i++) {
        var footerEl = document.getElementById('user-activity-text-' + i)
        footerEl.textContent = Math.floor(Math.random() * (13 - 2) + 2) + footerStrings[Math.floor(Math.random() * footerStrings.length)]
      }
    },
    loadMore() {
      document.getElementById('load-more').classList.add('d-none')
      document.getElementById('load-more-spinner').classList.remove('d-none')
      this.fetchMore(1)
    },
    fetchMore(push) {
      if (this.category.length > 0) {
        var api_link = "https://api.chachi.app/v2/bank/" + encodeURI(this.lang) + '/random/' + encodeURI(this.category) + '/' + encodeURI(this.categoryValue)
      } else {
        api_link = "https://api.chachi.app/v2/bank/" + encodeURI(this.lang) + '/random'
      }
      // push more cards to the existing array
      if (push > 0) {
        axios.get(api_link)
          .then( response => {
            response.data.forEach(element => {
              this.$store.commit('addCards', element)
            });
            document.getElementById('load-more').classList.remove('d-none')
            document.getElementById('load-more-spinner').classList.add('d-none')
          })
          .catch( err => console.log(err));
      }
      // fetch new cards
      else if (push === 0) { 
        axios.get(api_link)
          .then( response => {
            this.$store.commit('setCards', response.data)
            document.getElementById('loading-cards-spinner').classList.add('d-none')
            document.getElementById('category-random').classList.remove('d-none')
            document.getElementById('category-random-spinner').classList.add('d-none')
          })
          .catch( err => console.log(err));
      }
    },
    fetchAll() {
      this.$store.commit('setCategory', '')
      this.$store.commit('setCategoryValue', '')
      document.getElementById('category-random').classList.add('d-none')
      document.getElementById('category-random-spinner').classList.remove('d-none')
      this.fetchMore(0)
      this.toggleActiveButtons()
      document.getElementById('category-random').classList.add('active')
      this.footerText()
    },
    categorySearch(catto) {
      if (this.categories.subjects.indexOf(catto) > -1) {
        this.$store.commit('setCategory', 'subject')
      } else {
        this.$store.commit('setCategory', 'life_skill')
      }
      this.$store.commit('setCategoryValue', catto)
      this.fetchMore(0)
      this.toggleActiveButtons()
      document.getElementById('category-' + catto).classList.add('active')
      this.footerText()
    },
    toggleActiveButtons() {
      var cates = document.getElementById('categories').children
      for (var i=0; i < cates.length; i++) {
        cates[i].classList.remove('active')
      }
      document.getElementById('category-random').classList.remove('active')
    },
    infiniteMore() {
      window.onscroll = () => {
        let bottomOfWindow = document.documentElement.scrollTop + window.innerHeight === document.documentElement.offsetHeight;
        if (bottomOfWindow) {
          if (this.cards.length >= 15) {
            this.fetchMore(this.title, 1)
          }
        }
      }
    },
    showMore() {
        var categories = document.getElementById("categories")
        categories.classList.add('showContent')
        categories.classList.remove('hideContent')

        document.getElementById('show-more-categories').classList.add('d-none')
    },
  }
}
</script>

<style scoped>
.hideContent {
    overflow: hidden;
    line-height: 1.5em;
    height: 2.5em;
}
.showContent {
    line-height: 1.5em;
    height: auto;
}
.show-more {
    padding: 10px 0;
    text-align: center;
}
#show-more-categories {
  cursor: pointer;
}
.btn-outline-warning {
  color: var(--bs-dark);
  border-color: var(--bs-dark);
}
#load-more-spinner {
  width: 122.85px;
}
#category-random-spinner {
  width: 138.8px;
}
button > .spinner-border {
  width: 1rem;
  height: 1rem;
}
</style>